<template>
  <div class="app-container">
    <el-row>
      <span style="color: gray;font-size: 12px;">备注：点击图片预览并进行下载</span>
    </el-row>

    <div class="thumbnails">
      <div
          class="thumbnail"
          v-for="(imageFile, index) in images"
          :key="index"
          @click="handlePictureEnlarge(imageFile)"
      >
        <img :src="imageFile.filePic" :alt="imageFile.fileName" />
        <div style="margin: 15px;font-size: 12px;"></div>
      </div>
    </div>

    <el-dialog title="配置二维码" v-model="dialogVisiblePic" :close-on-click-modal="false"  @close="closeDialog" >
      <div id="test" :style="{width:enlargedImageWidth+'px',height:enlargedImageHeight+'px', maxHeight: '100%', display: 'block', margin: 'auto'  }">
        <div ref="imgWrap" class="wrap" @mousewheel.prevent="rollImg">
          <img id="moveImgId" class="moveImg" :src="userQrcodeImageUrl"  alt ref="image" @mousedown.prevent="moveImg" />
        </div>
        <img :src="enlargedImageUrl" alt="标准图" style="max-width: 100%; display: block; margin: auto;" >
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="info" @click="closeDialog">关 闭</el-button>
            <el-button type="primary" @click="handleNext()">下一步>添加内容</el-button>
            <el-button type="primary" @click="handleDownLoad()">直接下载</el-button>
          </span>
      </template>
    </el-dialog>


    <el-dialog title="添加内容" v-model="dialogVisiblePicText" :close-on-click-modal="false"  @close="closeTextDialog" >
      <div class="drawing-container">
        <!-- 绘图组件容器DOM -->
        <div id="tui-image-editor"></div>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="info" @click="closeTextDialog">关 闭</el-button>
            <el-button type="primary" @click="save()">下 载</el-button>
          </span>
      </template>
    </el-dialog>
  </div>



</template>


<script>
import { ref } from 'vue'
import {
  allGallery,
} from "@/api/galleryFile";
import html2canvas from "html2canvas";


import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import ImageEditor from 'tui-image-editor'
const locale_zh = {
  Undo: '撤销',
  Redo: '反撤销',
  Reset: '重置',

  Bold: '加粗',
  Italic: '斜体',
  Underline: '下划线',
  Color: '颜色',
  'Text size': '字体大小',
}

const customTheme = {
  "common.bi.image": "", // 左上角logo图片
  "common.bisize.width": "0px",
  "common.bisize.height": "0px",
  "common.backgroundImage": "none",
  "common.backgroundColor": "#f3f4f6",
  "common.border": "1px solid #333",

  // header
  "header.backgroundImage": "none",
  "header.backgroundColor": "#f3f4f6",
  "header.border": "0px",

  // load button
  "loadButton.backgroundColor": "#fff",
  "loadButton.border": "1px solid #ddd",
  "loadButton.color": "#222",
  "loadButton.fontFamily": "NotoSans, sans-serif",
  "loadButton.fontSize": "12px",
  "loadButton.display": "none", // 隐藏

  // download button
  "downloadButton.backgroundColor": "#fdba3b",
  "downloadButton.border": "1px solid #fdba3b",
  "downloadButton.color": "#fff",
  "downloadButton.fontFamily": "NotoSans, sans-serif",
  "downloadButton.fontSize": "12px",
  "downloadButton.display": "none", // 隐藏

  // icons default
  "menu.normalIcon.color": "#8a8a8a",
  "menu.activeIcon.color": "#555555",
  "menu.disabledIcon.color": "#ccc",
  "menu.hoverIcon.color": "#e9e9e9",
  "submenu.normalIcon.color": "#8a8a8a",
  "submenu.activeIcon.color": "#e9e9e9",

  "menu.iconSize.width": "24px",
  "menu.iconSize.height": "24px",
  "submenu.iconSize.width": "32px",
  "submenu.iconSize.height": "32px",

  // submenu primary color
  "submenu.backgroundColor": "#1e1e1e",
  "submenu.partition.color": "#858585",

  // submenu labels
  "submenu.normalLabel.color": "#858585",
  "submenu.normalLabel.fontWeight": "lighter",
  "submenu.activeLabel.color": "#fff",
  "submenu.activeLabel.fontWeight": "lighter",

  // checkbox style
  "checkbox.border": "1px solid #ccc",
  "checkbox.backgroundColor": "#fff",

  // rango style
  "range.pointer.color": "#fff",
  "range.bar.color": "#666",
  "range.subbar.color": "#d1d1d1",

  "range.disabledPointer.color": "#414141",
  "range.disabledBar.color": "#282828",
  "range.disabledSubbar.color": "#414141",

  "range.value.color": "#fff",
  "range.value.fontWeight": "lighter",
  "range.value.fontSize": "11px",
  "range.value.border": "1px solid #353535",
  "range.value.backgroundColor": "#151515",
  "range.title.color": "#fff",
  "range.title.fontWeight": "lighter",

  // colorpicker style
  "colorpicker.button.border": "1px solid #1e1e1e",
  "colorpicker.title.color": "#fff",
};



export default {
  name: 'Gallery',
  components: {},
  data() {
    return {
      listLoading: false,
      images: [],
      dialogVisiblePic: false,
      userQrcodeImageUrl: '',
      enlargedImageUrl: '',
      enlargedImageName: '',
      enlargedImageWidth: 0,
      enlargedImageHeight: 0,


      dialogVisiblePicText: false,
      instance: null,
      textImageUrl: '',
    }
  },
  setup(){
    const image = ref(null)
    const rollImg = (e) => {
      let transform = image.value.style.transform
      let zoom = transform.indexOf("scale") != -1 ? +transform.split("(")[1].split(")")[0] : 1
      zoom += e.wheelDelta / 1200
      if (zoom > 0.1 && zoom < 2) {
        image.value.style.transform = "scale(" + zoom + ")"
      }
    };

    const imgWrap = ref(null)
    const moveImg = (e) => {
      let wrap = imgWrap.value
      let img = image.value
      let x = e.pageX - img.offsetLeft
      let y = e.pageY - img.offsetTop
      // 添加鼠标移动事件
      wrap.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener('mouseup', () => {
        wrap.removeEventListener('mousemove', move)
      })
      // 鼠标离开父级元素，把事件移除
      wrap.addEventListener('mouseout', () => {
        wrap.removeEventListener('mousemove', move)
      })
    };

    return {
      image,
      rollImg,
      imgWrap,
      moveImg
    }
  },
  created() {
    this.fetchAll();
    let str = sessionStorage.getItem("user") || "{}"
    let form = JSON.parse(str);
    this.userQrcodeImageUrl = form.qrcode;
  },
  methods: {
    //列表
    fetchAll() {
      this.listLoading = true;
      allGallery().then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.images = res.data;
        }
      })
    },

    //放大图片
    handlePictureEnlarge(imageFile) {
      this.dialogVisiblePic = true;
      this.enlargedImageUrl = imageFile.filePic;
      this.enlargedImageName = imageFile.fileName;
      this.enlargedImageWidth = imageFile.fileWidth;
      this.enlargedImageHeight = imageFile.fileHeight;

      let byClass = document.getElementById('moveImgId');
      if(byClass){
        byClass.style.left = '0px';
        byClass.style.top = '0px';
      }

    },
    //关闭框
    closeDialog(){
      this.dialogVisiblePic = false;
    },
    // 直接保存镶嵌二维码
    handleDownLoad(){
      let dom = document.getElementById("test");
      var width = this.enlargedImageWidth;
      var height = this.enlargedImageHeight;
      //调用方法下载
      html2canvas(dom, {
        width: width,
        height: height,
        backgroundColor: null, //背景颜色 为null是透明
        scale: 1,
        useCORS: true, //这个为true代表允许跨域
        allowTaint: false,
        logging: false,
        letterRendering: true,
      }).then((canvas) => {
        let posterImg = canvas.toDataURL("image/png"); //转base64
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = this.enlargedImageName; // 设置图片名称
        a.href = posterImg; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },


    //添加文字内容框
    handleNext() {
      this.dialogVisiblePicText = true;

      let dom = document.getElementById("test");
      let width = this.enlargedImageWidth;
      let height = this.enlargedImageHeight;
      //调用方法下载
      html2canvas(dom, {
        width: width,
        height: height,
        backgroundColor: null, //背景颜色 为null是透明
        scale: 1,
        useCORS: true, //这个为true代表允许跨域
        allowTaint: false,
        logging: false,
        letterRendering: true,
      }).then((canvas) => {
        this.textImageUrl = canvas.toDataURL("image/png"); //转base64
        this.init();
      });

    },
    //关闭文字内容框
    closeTextDialog(){
      this.dialogVisiblePicText = false;
    },
    init() {
      this.$nextTick(() => {
        this.instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
          includeUI: {
            loadImage: {
              path: this.textImageUrl,
              name: 'image'
            },
            menu: ['text'], // 底部菜单按钮列表
            initMenu: 'text', // 默认打开的菜单项
            menuBarPosition: 'bottom', // 菜单所在的位置
            locale: locale_zh, // 本地化语言为中文
            theme: customTheme // 自定义样式
          },
          cssMaxWidth: 1000, // canvas 最大宽度
          cssMaxHeight: 600 // canvas 最大高度
        })

        document.getElementsByClassName('tui-image-editor-main')[0].style.top = '45px' // 调整图片显示位置
        document.getElementsByClassName(
            'tie-btn-zoomIn tui-image-editor-item help'
        )[0].style.display = 'none' // 隐藏顶部zoomIn按钮
        document.getElementsByClassName(
            'tie-btn-zoomOut tui-image-editor-item help'
        )[0].style.display = 'none' // 隐藏顶部zoomOut按钮
        document.getElementsByClassName(
            'tie-btn-hand tui-image-editor-item help'
        )[0].style.display = 'none' // 隐藏顶部hand按钮
        document.getElementsByClassName(
            'tie-btn-history tui-image-editor-item help'
        )[0].style.display = 'none' // 隐藏顶部history按钮
        document.getElementsByClassName(
            'tie-btn-delete tui-image-editor-item help'
        )[0].style.display = 'none' // 隐藏顶部delete按钮
        document.getElementsByClassName(
            'tie-btn-deleteAll tui-image-editor-item help'
        )[0].style.display = 'none' // 隐藏顶部deleteAll按钮
        document.getElementsByClassName('tui-image-editor-icpartition'
        )[0].style.display = 'none' // 隐藏顶部分割线按钮
        document.getElementsByClassName('tui-image-editor-icpartition'
        )[1].style.display = 'none' // 隐藏顶部分割线按钮
        document.getElementsByClassName('tie-text-align-button'
        )[0].style.display = 'none' // 隐藏文字对齐方式按钮
        document.getElementsByClassName('tui-image-editor-partition'
        )[0].style.display = 'none' // 隐藏文字分割线按钮

      })

    },
    // 保存图片
    save() {
      const base64String = this.instance.toDataURL() // base64 文件
      this.downloadFile(base64String,this.enlargedImageName, );
    },

    // 下载
    downloadFile(url, fileName) {
      const a = document.createElement("a");
      a.setAttribute("href", url)
      a.setAttribute("download", fileName)
      a.setAttribute("target", "_blank")
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },


  }
}



</script>

<style scoped>

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  padding:40px;
}

.thumbnail {
  width: 200px;
  border: 1px dashed #f7f3f3;
  margin: 10px;
  cursor: pointer;
}

.thumbnail img {
  width: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.wrap {
  position: relative;
}
.moveImg {
  position: absolute;
  cursor: move;
  width: 200px;
  height: 200px;
  top: 0px;
  left: 0px;
  transform: scale(0.4);
}

.tui-image-editor-container{
  min-height: 900px;
}

</style>
